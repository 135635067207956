import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import ShipmentTracking from "./ShipmentTracking";

export default function App() {
  const theme = useTheme();
  const belowMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split("/").pop()}`;
  };

  return (
    <Router
      // basename={getBasename()}
      // basename="/TCM_Tracker"
    >
      <Box
        sx={{
          flexGrow: 1,
          px: belowMediumScreen ? 0 : 24,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
        className="bgimage"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Routes>
                <Route path="/" element={<ShipmentTracking />} />
              </Routes>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Router>
  );
}
